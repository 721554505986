import {
  BlockBlobClient,
  BlockBlobParallelUploadOptions,
  BlobUploadCommonResponse,
} from '@azure/storage-blob';
import { IError } from '@guider-global/shared-types';

export interface IUploadToBlobResult {
  errors: IError[];
  status: 'success' | 'error';
  url?: string;
}

export async function uploadToBlob(
  uri: string,
  sasToken: string,
  file: File,
): Promise<IUploadToBlobResult> {
  try {
    const blockBlobClient: BlockBlobClient = new BlockBlobClient(
      `${uri}?${sasToken}`,
    );
    const options: BlockBlobParallelUploadOptions = {
      blobHTTPHeaders: { blobContentType: file.type },
    };
    const uploadResult: BlobUploadCommonResponse =
      await blockBlobClient.uploadData(file, options);
    // Handle error
    if (uploadResult._response.status !== 201) {
      const { _response } = uploadResult;

      const { status } = _response;
      return {
        status: 'error',
        errors: [{ code: status, message: 'Failed to upload file' }],
      };
    }

    return {
      status: 'success',
      errors: [],
      url: uploadResult._response.request.url,
    };
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.log(err.message);
      return { status: 'error', errors: [{ message: err.message }] };
    }
    return {
      status: 'error',
      errors: [{ message: 'Unable to download file' }],
    };
  }
}
