import { KeyedProps } from '@guider-global/shared-types';
import {
  ContentModal,
  KeyStat,
  KeyStatProps,
  Table,
  LeaderboardRow,
  ProgressIndicatorDial,
  Stack,
  Text,
  AvatarBox,
  FirstPositionIcon,
  SecondPositionIcon,
  ThirdPositionIcon,
} from '@guider-global/ui';
import { Box, ButtonBase, Link } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

export type ProgressLeaderboardModalViewProps = {
  open: boolean;
  heading: string | undefined;
  yourContributionsLabel: string | undefined;
  onClose?: () => void;
  dialSize?: number;
  dialMetricLabel: string;
  dialMetricDescription: string | undefined;
  keyStats: KeyedProps<KeyStatProps>[];
  leaderboardRows: LeaderboardRow[];
  loadingRows: boolean;
  rankColumnLabel: string | undefined;
  profileColumnLabel: string | undefined;
  contributionsColumnLabel: string | undefined;
  page: number;
  pageSize: number;
  rowCount: number;
  handlePageChange: (page: number) => void;
};

export function ProgressLeaderboardModalView({
  open,
  heading,
  yourContributionsLabel,
  onClose,
  dialSize = 12,
  dialMetricLabel,
  dialMetricDescription,
  keyStats,
  leaderboardRows,
  loadingRows,
  rankColumnLabel,
  profileColumnLabel,
  contributionsColumnLabel,
  page,
  pageSize,
  rowCount,
  handlePageChange,
}: ProgressLeaderboardModalViewProps) {
  const columns: GridColDef<LeaderboardRow>[] = [
    {
      field: 'rank',
      headerName: rankColumnLabel,
      flex: 1,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,

      renderCell: (params: GridRenderCellParams<LeaderboardRow, number>) => {
        if (params.value === 1) {
          return <FirstPositionIcon fontSize="large" />;
        }
        if (params.value === 2) {
          return <SecondPositionIcon fontSize="large" />;
        }
        if (params.value === 3) {
          return <ThirdPositionIcon fontSize="large" />;
        }
        return (
          <Text text={`${params.value}. `} variant="body1" fontSize={18} />
        );
      },
    },

    {
      field: 'displayName',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: profileColumnLabel,
      renderCell: (params: GridRenderCellParams<LeaderboardRow, string>) => (
        <Box display="inline-block" py={0.5}>
          <Link href={params.row.href}>
            <ButtonBase onClick={params.row.onClick}>
              <AvatarBox
                avatarSrc={params.row.avatarSrc}
                heading={{
                  text: params.value,
                  variant: 'body1',
                  color: (theme) => theme.palette.paper.text.primary,
                }}
                size={'small'}
              />
            </ButtonBase>
          </Link>
        </Box>
      ),
      flex: 3,
    },
    {
      field: 'contributions',
      headerName: contributionsColumnLabel,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  ];
  return (
    <ContentModal
      paperSx={{
        minWidth: { md: 'fit-content' },
        height: { md: 'fit-content' },
      }}
      bodySx={{
        p: 0,
        pt: 2,
      }}
      open={open}
      heading={heading}
      onClose={onClose}
    >
      <Stack direction="column" sx={{ width: { xs: '100%', md: 'initial' } }}>
        <Text
          text={yourContributionsLabel}
          px={2}
          sx={{
            fontWeight: 500,
            pr: 2,
            pl: { xs: 2, md: 3 },
            pb: { xs: 2, md: 1 },
          }}
        />
        <Stack
          px={2}
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={{
            xs: 0,
            md: 3,
          }}
          sx={{ width: { xs: '100%', md: 'initial' } }}
          alignItems="flex-start"
        >
          <ProgressIndicatorDial
            percentComplete={100}
            size={dialSize}
            metricLabel={dialMetricLabel}
            metricDescription={dialMetricDescription}
            containerSx={{
              order: {
                xs: 1,
                md: 0,
              },
            }}
          />
          <Stack direction="row" justifyContent="space-between" sx={{ pr: 2 }}>
            {keyStats.map((stat) => (
              <KeyStat
                key={stat.key}
                align="center"
                divider={false}
                value={stat.value}
                label={stat.label}
              />
            ))}
          </Stack>
        </Stack>
        <Box px={0} py={1}>
          <Table
            loading={loadingRows}
            columns={columns}
            rows={leaderboardRows}
            getEstimatedRowHeight={() => 52}
            pageSizeOptions={[5]}
            paginationModel={{
              page: page - 1,
              pageSize,
            }}
            rowCount={rowCount}
            paginationMode="server"
            onPaginationModelChange={(model) =>
              handlePageChange(model.page + 1)
            }
          />
        </Box>
      </Stack>
    </ContentModal>
  );
}
