export function deepMerge<T>(target: T, source: T): T {
  if (typeof target !== 'object' || typeof source !== 'object') {
    return source;
  }

  const merged: Partial<T> = { ...target };

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (merged.hasOwnProperty(key)) {
        merged[key] = deepMerge(merged[key], source[key]);
      } else {
        merged[key] = source[key];
      }
    }
  }

  return merged as T;
}
