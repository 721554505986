import { formatInTimeZone } from 'date-fns-tz';

export interface GetSessionDateAndDurationProps {
  sessionStart: string;
  sessionEnd: string;
  locale: Locale;
  timezone: string;
  formattedTime: string;
}

export function getSessionDateAndDuration({
  sessionStart,
  sessionEnd,
  locale,
  timezone,
  formattedTime,
}: GetSessionDateAndDurationProps) {
  const start = formatInTimeZone(
    new Date(sessionStart),
    timezone,
    `${formattedTime}`,
    {
      locale,
    },
  );

  const durationInMinutes = Math.abs(
    (new Date(sessionEnd).getTime() - new Date(sessionStart).getTime()) / 60000,
  );
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;

  var duration;

  if (hours === 0) {
    duration = `${minutes} minutes`;
  } else if (hours === 1 && minutes === 0) {
    duration = `${hours} hour`;
  } else if (hours === 1) {
    duration = `${hours} hour ${minutes} minutes`;
  } else if (minutes === 0) {
    duration = `${hours} hours`;
  } else {
    duration = `${hours} hours ${minutes} minutes`;
  }

  return (
    formatInTimeZone(
      new Date(sessionStart ?? ''),
      timezone ?? '',
      `EEEE, d MMM`,
      { locale },
    ) +
    ' at ' +
    start +
    ' • ' +
    duration
  );
}
