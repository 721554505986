// external
import { AlertColor } from '@mui/material/Alert';
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';

// internal
import { RootState } from '..';

// types
import { INavbarLink } from 'containers';
import { IGenericErrorProps } from 'components/GenericError';

export type CustomLegalAgreement = string;

export type TModalId =
  | 'termsOfService'
  | 'privacyPolicy'
  | 'traineeModal'
  | CustomLegalAgreement;
export interface IAlertProps {
  show: boolean;
  severity: AlertColor;
  message: string;
  timeout: number;
}

export type RegistrationTypes =
  | 'localAccountsAndSSO'
  | 'localAccounts'
  | 'multitenantsSSO'
  | 'none';

export interface IApp {
  navbar: {
    hidden: boolean;
    unauthenticatedActions: INavbarLink[];
    registrationType: RegistrationTypes;
  };
  loading: boolean;
  modals: Record<TModalId, boolean>;
  alert: IAlertProps;
  confetti: {
    show: boolean;
  };
  error: IGenericErrorProps;
}

const initialState: IApp = {
  navbar: {
    hidden: false,
    unauthenticatedActions: [],
    registrationType: 'localAccountsAndSSO',
  },
  loading: true,
  modals: {
    termsOfService: false,
    privacyPolicy: false,
    traineeModal: false,
  },
  alert: {
    show: false,
    severity: 'success',
    message: '',
    timeout: 100000,
  },
  confetti: {
    show: false,
  },
  error: {
    code: undefined,
    message: undefined,
    visible: false,
    overlay: false,
  },
};

export const appSlice: Slice<IApp> = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startAppLoading: (state) => {
      state.loading = true;
    },
    stopAppLoading: (state) => {
      state.loading = false;
    },
    toggleModal: (state, action: PayloadAction<TModalId>) => {
      state.modals[action.payload] = !state.modals[action.payload];
    },
    setNavbarUnauthenticatedActions: (
      state,
      action: PayloadAction<INavbarLink[]>,
    ) => {
      state.navbar.unauthenticatedActions = action.payload;
    },
    showNavbar: (state) => {
      state.navbar.hidden = false;
    },
    hideNavbar: (state) => {
      state.navbar.hidden = true;
    },
    showConfetti: (state) => {
      state.confetti.show = true;
    },
    hideConfetti: (state) => {
      state.confetti.show = false;
    },
    showAppAlert: (
      state,
      action: PayloadAction<{
        severity: AlertColor;
        message: string;
        timeout?: number;
      }>,
    ) => {
      const { severity, message, timeout = 10000 } = action.payload;

      state.alert = {
        show: true,
        severity,
        message,
        timeout,
      };
    },
    hideAppAlert: (state) => {
      state.alert = {
        ...state.alert,
        show: false,
      };
    },
    updateRegistrationType: (
      state,
      action: PayloadAction<RegistrationTypes>,
    ) => {
      state.navbar.registrationType = action.payload;
    },
    showAppError: (state, action: PayloadAction<IGenericErrorProps>) => {
      state.error = action.payload;
    },
    hideAppError: (state) => {
      state.error = { ...state.error, visible: false };
    },
  },
});

export const {
  startAppLoading,
  stopAppLoading,
  toggleModal,
  setNavbarUnauthenticatedActions,
  showNavbar,
  hideNavbar,
  showAppAlert,
  hideAppAlert,
  showConfetti,
  hideConfetti,
  updateRegistrationType,
  showAppError,
  hideAppError,
} = appSlice.actions;

export const selectConfetti = (state: RootState) => state.app.confetti;

export const selectAppAlert = (state: RootState): IAlertProps =>
  state.app.alert;

export const selectAppLoading = (state: RootState): boolean =>
  state.app.loading;

export const selectAppNavbarHidden = (state: RootState): boolean =>
  state.app.navbar.hidden;

export const selectAppError = (state: RootState): IGenericErrorProps =>
  state.app.error;

export const selectRegistrationType = (state: RootState): RegistrationTypes =>
  state.app.navbar.registrationType;

export const selectAppModal =
  (modalId: TModalId) =>
  (state: RootState): boolean =>
    state.app.modals[modalId];

export default appSlice.reducer;
