export const areArraysEqual = (array1: string[], array2: string[]) => {
  const array1Sorted = array1.slice().sort();
  const array2Sorted = array2.slice().sort();

  if (array1.length !== array2.length) {
    return false;
  }

  return array1Sorted.every((value, index) => value === array2Sorted[index]);
};

export const shuffleArray = (array: string[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};
