import { Button } from '@guider-global/ui/lib/components/Buttons';
import {
  Checkbox,
  CheckboxProps,
} from '@guider-global/ui/lib/components/Inputs/Checkbox';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { Stack } from '@mui/material';
import { useState } from 'react';
type GoalCategoriesViewProps = {
  goalCategories: {
    label: string;
    slug: string;
  }[];
  buttonLabels: {
    more: string | undefined;
    less: string | undefined;
  };
  selectedGoalCategories?: string[];
  onSelected: (slugs: string[]) => void;
} & CheckboxProps;

function GoalCategoriesView({
  goalCategories,
  selectedGoalCategories = [],
  onSelected,
  buttonLabels,
}: GoalCategoriesViewProps) {
  const [showMore, setShowMore] = useState<boolean>(false);

  function isGoalCategorySelected(goalCategorySlug: string) {
    return selectedGoalCategories.includes(goalCategorySlug);
  }
  const toggleGoalCategorySelect = (slug: string) => {
    onSelected(
      isGoalCategorySelected(slug)
        ? selectedGoalCategories.filter((selected) => selected !== slug)
        : [...selectedGoalCategories, slug],
    );
  };
  return (
    <Stack direction={'column'} py={3} spacing={2}>
      {goalCategories
        .slice(0, showMore ? undefined : 5)
        .map(({ slug, label }, index) => (
          <Checkbox
            key={slug}
            variant="option"
            heading={label}
            isChecked={isGoalCategorySelected(slug) ?? false}
            onChange={() => toggleGoalCategorySelect(slug)}
            data-cy={`register-goal-category-${index}`}
          />
        ))}
      <Button
        sx={{
          display: goalCategories.length > 5 ? '' : 'none',
        }}
        variant="text"
        label={
          showMore ? buttonLabels.less ?? 'Less' : buttonLabels.more ?? 'More'
        }
        endIcon={showMore ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={() => setShowMore(!showMore)}
        data-cy="goal-categories-show-more-less-button"
      />
    </Stack>
  );
}

export default GoalCategoriesView;
