import {
  CreateReduxSlice,
  createReduxSlice,
} from '@guider-global/redux-axios-hooks';
import { IProfile } from '@guider-global/shared-types';
import { SliceCaseReducers } from '@reduxjs/toolkit';

export interface IUser {
  id: string;
  email: string;
  givenName?: string;
  familyName?: string;
  profile?: IProfile;
}

const initialState: IUser = {
  id: '',
  email: '',
};

export const userSlice: CreateReduxSlice<
  IUser,
  SliceCaseReducers<IUser>,
  'user'
> = createReduxSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserMetadata: (state, action) => {
      const { id, email, givenName, familyName } = action.payload;

      state.id = id;
      state.email = email;
      state.givenName = givenName;
      state.familyName = familyName;
    },
    setUserProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const { setUserMetadata, setUserProfile } = userSlice.actions;

export default userSlice.reducer;
