import {
  IMatchedGoalCategory,
  IMatchedSkill,
  IMatchV2,
  IMembership,
  IProfile,
  IRelationship,
  ProfileSkill,
  SanityGoalCategory,
  SanitySkills,
} from '@guider-global/shared-types';

export interface GetSkillLabelsProps {
  options: SanitySkills;
  source: IMembership | IProfile | IMatchV2 | IRelationship | undefined;
}

export interface GetGoalCategoryLabelsProps {
  options: SanityGoalCategory[] | undefined;
  source: IMembership | IProfile | IMatchV2 | undefined;
}

export function getSkillLabels({ options, source }: GetSkillLabelsProps) {
  const hardSkillOptions = options?.hard_skills?.flatMap(
    (category) => category.skills,
  );
  const softSkillOptions = options?.soft_skills?.flatMap(
    (category) => category.skills,
  );

  const allSkills = [
    ...(hardSkillOptions ?? []),
    ...(softSkillOptions ?? []),
  ] as ProfileSkill[];

  return (source?.skills as Partial<IMatchedSkill>[])
    ?.map((skill, index) => {
      const label =
        allSkills?.find((s) => s?.slug === skill?.fieldSlug)?.label ??
        undefined;
      return {
        key: `${skill?.fieldSlug}-${index}`,
        isMatched: skill?.matched,
        value: label ?? '',
      };
    })
    .filter(Boolean);
}

export function getGoalCategoryLabels({
  options,
  source,
}: GetGoalCategoryLabelsProps) {
  return (source?.goalCategories as Partial<IMatchedGoalCategory>[])
    ?.map((goal, index) => {
      const label = options?.find(
        (option) => option?.goal_category_slug?.current === goal?.fieldSlug,
      )?.goal_category_name;

      return {
        key: `${goal?.fieldSlug}-${index}`,
        isMatched: goal?.matched,
        value: label ?? '',
      };
    })
    .filter(Boolean);
}
