import { IProfile, IRelationship } from '@guider-global/shared-types';

interface GetRecipientParams {
  role: 'guide' | 'trainee';
  relationship?: IRelationship;
  userId?: string;
}

export interface IRecipients extends Partial<IProfile> {
  userPicture?: string;
}

export const getRecipients = ({
  role,
  userId,
  relationship,
}: GetRecipientParams): IRecipients[] => {
  if (role === 'guide') {
    const trainees = relationship?.traineeProfiles?.map((trainee) => {
      const user = relationship?.users?.find(
        (user) => user.id === trainee.userId,
      );
      return {
        ...trainee,
        userPicture: user?.picture,
      };
    });

    return trainees ?? [];
  }
  const guideProfiles = relationship?.guideProfiles ?? [];

  const guides = guideProfiles.map((guide) => {
    const user = relationship?.users?.find((user) => user.id === guide.userId);
    return {
      ...guide,
      userPicture: user?.picture,
    };
  });
  const otherTraineesProfiles =
    relationship?.traineeProfiles?.filter((trainee) => trainee !== userId) ??
    [];
  const otherTrainees = otherTraineesProfiles.map((trainee) => {
    const user = relationship?.users?.find(
      (user) => user.id === trainee.userId,
    );
    return {
      ...trainee,
      userPicture: user?.picture,
    };
  });

  return [...guides, ...otherTrainees];
};
