// external
import {
  CreateReduxSlice,
  createReduxSlice,
} from '@guider-global/redux-axios-hooks';
import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface IGroupGuideForm {
  title: string;
  description: string;
  skills?: string[];
  [key: string]: string | string[] | undefined;
}
export interface IMembershipForm {
  title?: string;
  description?: string;
  skills?: string[];
  [key: string]: string | string[] | undefined;
}
export interface IMembershipTraineeForm extends IMembershipForm {
  goals?: string[];
}

export interface IForms {
  memberships: {
    guide: {
      [key: string]: IMembershipForm;
    };
    trainee: {
      [key: string]: IMembershipTraineeForm;
    };
  };
  groups: {
    guide: {
      [key: string]: IGroupGuideForm;
    };
    trainee: {
      [key: string]: IMembershipForm;
    };
  };
}

const initialState: IForms = {
  memberships: {
    guide: {},
    trainee: {},
  },
  groups: {
    guide: {},
    trainee: {},
  },
} as IForms;

export const formsSlice: CreateReduxSlice<
  IForms,
  SliceCaseReducers<IForms>,
  'forms'
> = createReduxSlice({
  name: 'forms',
  initialState,
  reducers: {
    setMembershipGuideForm: (
      state,
      action: PayloadAction<{ programSlug: string; data: IMembershipForm }>,
    ) => {
      state.memberships.guide[action.payload.programSlug] = action.payload.data;
    },
    setMembershipTraineeForm: (
      state,
      action: PayloadAction<{
        programSlug: string;
        data: IMembershipTraineeForm;
      }>,
    ) => {
      state.memberships.trainee[action.payload.programSlug] =
        action.payload.data;
    },
    setGroupsGuideForm: (
      state,
      action: PayloadAction<{ programSlug: string; data: IGroupGuideForm }>,
    ) => {
      state.groups.guide[action.payload.programSlug] = action.payload.data;
    },
    setGroupsTraineeForm: (
      state,
      action: PayloadAction<{
        programSlug: string;
        data: IMembershipForm;
      }>,
    ) => {
      state.groups.trainee[action.payload.programSlug] = action.payload.data;
    },
    setMembershipTraineeGoals: (
      state,
      action: PayloadAction<{ programSlug: string; goals: string[] }>,
    ) => {
      state.memberships.trainee[action.payload.programSlug].goals =
        action.payload.goals;
    },
  },
});

export const {
  setMembershipGuideForm,
  setMembershipTraineeForm,
  setMembershipTraineeGoals,
  setGroupsGuideForm,
  setGroupsTraineeForm,
} = formsSlice.actions;

export const selectMembershipGuideForm = (
  state: RootState,
  programSlug: string,
) => state.forms.memberships.guide[programSlug];

export const selectMembershipTraineeForm = (
  state: RootState,
  programSlug: string,
) => state.forms.memberships.trainee[programSlug];

export const selectGroupGuideForm = (state: RootState, programSlug: string) =>
  state.forms.groups.guide[programSlug];

export const selectGroupTraineeForm = (state: RootState, programSlug: string) =>
  state.forms.groups.trainee[programSlug];

export default formsSlice.reducer;
