import { EProgramVariation } from '@guider-global/shared-types';
import {
  ButtonStack,
  Image,
  MainColors,
  Stack,
  TextStack,
} from '@guider-global/ui';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

export type OnboardingProgram = {
  name: string;
  description: string | undefined;
  image: string | undefined;
  type: string | undefined;
  displayPreference: 'center' | 'top' | 'left' | 'right' | 'bottom' | undefined;
  programSlug: string | undefined;
  joinAsMenteeButton: string | undefined;
  joinAsMentorButton: string | undefined;
  chipColor: MainColors | undefined;
  variation?: EProgramVariation;
};

export interface OnboardingProgramViewProps {
  program: OnboardingProgram | undefined;
}

export function OnboardingProgramView({ program }: OnboardingProgramViewProps) {
  const navigate = useNavigate();
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      gap={1}
      alignItems={'flex-start'}
    >
      <Box width={{ xs: '100%', md: '50%' }}>
        <Image
          height={'420px'}
          src={program?.image ?? ''}
          width={'100%'}
          sx={{ borderRadius: '16px' }}
        />
      </Box>
      <Stack
        direction={'column'}
        width={{ xs: '100%', md: '50%' }}
        height={'100%'}
        gap={1}
        justifyContent={'space-between'}
      >
        <TextStack
          size="large"
          heading={program?.name}
          spacing={1}
          subtitles={[
            {
              text: program?.description,
              fontSize: '16px',
              color: 'text.secondary',
            },
          ]}
          sx={{
            h2: {
              lineHeight: '115%',
            },
          }}
        />
        <ButtonStack
          width={{ xs: '100%' }}
          direction={{ xs: 'column' }}
          spacing={1.5}
          buttons={[
            {
              key: 'onboarding-programs-btn-1',
              variant: 'contained',
              label: program?.joinAsMentorButton,
              onClick: () =>
                navigate(`/programs/${program?.programSlug}/join/guide`),
              color: 'info',
              sx: { px: 3 },
            },
            {
              key: 'onboarding-programs-btn-2',
              variant: 'outlined',
              label: program?.joinAsMenteeButton,
              onClick: () =>
                navigate(`/programs/${program?.programSlug}/join/trainee`),
              color: 'info',
              sx: {
                px: 3,
              },
            },
          ]}
        />
      </Stack>
    </Stack>
  );
}
