import { Button, Stack, TextStack } from '@guider-global/ui';
import { Box } from '@mui/system';

import { useNavigate } from 'react-router-dom';

type SurveyErrorViewProps = {
  title: string | undefined;
  description: string | undefined;
  buttonLabel: string | undefined;
};

export function SurveyErrorView({
  title,
  description,
  buttonLabel,
}: SurveyErrorViewProps) {
  const navigate = useNavigate();

  return (
    <Stack direction={'column'} gap={2}>
      <TextStack
        spacing={2}
        align="center"
        size="xl"
        heading={title}
        subtitles={[
          {
            text: description,
            color: 'text.secondary',
            fontSize: '14px',
            fontWeight: 500,
          },
        ]}
      />
      <Box display={'flex'} justifyContent={'center'}>
        <Button
          label={buttonLabel}
          color="info"
          onClick={() => navigate('/dashboard')}
          sx={{ px: 3 }}
        />
      </Box>
    </Stack>
  );
}
