import { CallCompositePage } from '@azure/communication-react';
import {
  CreateReduxSlice,
  createReduxSlice,
} from '@guider-global/redux-axios-hooks';
import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from 'store';

export type SidebarType = 'chat' | 'goal' | 'session';

export interface VideoRoomState {
  transitionSidebar: boolean;
  sidebar: SidebarType;
  callCompositePage?: CallCompositePage;
}

const initialState: VideoRoomState = {
  transitionSidebar: false,
  sidebar: 'chat',
  callCompositePage: 'configuration',
};

export const videoRoomSlice: CreateReduxSlice<
  VideoRoomState,
  SliceCaseReducers<VideoRoomState>,
  'videoRoom'
> = createReduxSlice({
  name: 'videoRoom',
  initialState,
  reducers: {
    setTransitionSidebar: (state) => {
      state.transitionSidebar = !state.transitionSidebar;
    },
    setSidebar: (state, action: PayloadAction<SidebarType>) => {
      state.sidebar = action.payload;
    },
    setCallCompositePage: (state, action: PayloadAction<CallCompositePage>) => {
      state.callCompositePage = action.payload;
    },
  },
});

export const { setTransitionSidebar, setSidebar, setCallCompositePage } =
  videoRoomSlice.actions;

export const getVideoRoomState = (state: RootState) => state.videoRoom;

export default videoRoomSlice.reducer;
