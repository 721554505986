import {
  MdArticle,
  MdAudiotrack,
  MdLink,
  MdNote,
  MdPlayCircleFilled,
} from 'react-icons/md';

import { SanityContentLibrary } from '@guider-global/shared-types';

import { MdiFilePdfBox } from 'assets/mdi-pdf-icon';

export const getContentTypeIcon = ({
  type,
}: {
  type: SanityContentLibrary['metadata']['type'] | undefined;
}) => {
  switch (type) {
    case 'article':
      return <MdArticle />;
    case 'audio':
      return <MdAudiotrack />;
    case 'link':
      return <MdLink />;
    case 'pdf':
      return <MdiFilePdfBox />;
    case 'video':
      return <MdPlayCircleFilled />;
    default:
      return <MdNote />;
  }
};
