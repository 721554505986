export const hexToRGB = (h: string) => {
  if (!h.includes('#')) {
    return h.replace('rgb(', '').replace(')', '');
  }

  let r = '',
    g = '',
    b = '';

  // 3 digits
  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return +r + ',' + +g + ',' + +b;
};
