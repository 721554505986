import { useProfileImage } from '@guider-global/azure-storage-hooks';
import {
  GridTable,
  IconButton,
  Stack,
  Text,
  TextStack,
} from '@guider-global/ui';
import { ArrowForward, Edit } from '@mui/icons-material';
import { Avatar } from '@mui/material';

export interface IDashboardRelationshipsRow {
  name: string;
  userPicture?: string;
  profilePicture?: string;
  description?: string;
  numberOfSessions: string;
  nextSessionLabel?: string;
  nextSessionColor?: string;
  onClick?: () => void;
  createdAt?: Date;
  editGroupLink?: () => void;
  goToGroupLink?: () => void;
}
export interface IDashboardRelationshipsHeaders {
  name: string | undefined;
  numberOfSessions: string | undefined;
  nextSession: string | undefined;
}
export interface ProgramMembershipTableViewProps {
  rows: IDashboardRelationshipsRow[];
  headers: IDashboardRelationshipsHeaders;
  isGroupGuide?: boolean;
}

export function ProgramMembershipTableView({
  rows,
  headers,
  isGroupGuide,
}: ProgramMembershipTableViewProps) {
  // Table header

  const { getProfileImage } = useProfileImage();

  return (
    <GridTable
      rowsPerPage={20}
      header={{
        cells: [
          {
            key: 'name',
            content: <Text text={headers.name} />,
            xs: 8,
            md: 5,
            pr: 1,
          },
          {
            key: 'sessions_number',
            content: <Text text={headers.numberOfSessions} />,
            md: 3,
            pr: 1,
            display: { xs: 'none', md: isGroupGuide ? 'none' : 'inherit' },
          },
          {
            key: 'next_session',
            content: <Text text={headers.nextSession} />,
            xs: 4,
            pr: 1,
          },
        ],
        sx: {
          p: 2,
        },
      }}
      rows={rows.map((row, index) => {
        return {
          onClick: isGroupGuide ? undefined : row.onClick,
          sx: {
            borderRadius: rows.length - 1 === index ? '0px 0px 16px 16px' : 0,
          },
          cells: [
            {
              key: 'name',
              content: (
                <Stack direction="row" spacing={1}>
                  <Avatar
                    src={getProfileImage({
                      profilePicture: row.profilePicture,
                      userPicture: row.userPicture,
                    })}
                    sx={{
                      width: '38px',
                      height: '38px',
                    }}
                    variant="rounded"
                  />
                  <TextStack
                    size="xs"
                    heading={row.name}
                    sx={{ wordBreak: 'break-word' }}
                    subtitles={[row.description]}
                  />
                </Stack>
              ),
              xs: 8,
              md: 5,
              pr: 1,
            },
            {
              key: 'session_number',
              content: <Text text={row.numberOfSessions.toString() ?? '0'} />,
              md: 3,
              pr: 1,
              display: { xs: 'none', md: isGroupGuide ? 'none' : 'inherit' },
            },
            {
              key: 'next_session',
              content: (
                <Text
                  text={row.nextSessionLabel}
                  color={row.nextSessionColor}
                />
              ),
              xs: 4,
              pr: 1,
            },
            {
              key: 'edit_group',
              content: (
                <Stack direction={'row'} spacing={1}>
                  <IconButton
                    icon={<Edit />}
                    variant={'regular'}
                    onClick={row.editGroupLink}
                  />
                  <IconButton
                    icon={<ArrowForward />}
                    variant={'regular'}
                    onClick={row.goToGroupLink}
                  />
                </Stack>
              ),
              justifyContent: 'flex-end',
              flex: '1 1 0',
              display: isGroupGuide ? 'inherit' : 'none',
            },
          ],
        };
      })}
      rowProps={{
        sx: {
          p: 2,
          borderTop: `1px solid #F2F4F7`,
          cursor: isGroupGuide ? 'default' : 'pointer',
          backgroundColor: 'white',
          overflow: 'hidden',
          '&:hover': {
            backgroundColor: isGroupGuide
              ? 'none'
              : (theme) => theme.palette.paper.hover.light,
          },
        },
      }}
      sx={{
        boxShadow: 'none',
        border: `1px solid #F2F4F7`,
        borderRadius: '16px',
        backgroundColor: '#F8FBFF',
      }}
      showNavigator={false}
    />
  );
}
