import { ISessionVideoConferencing } from '@guider-global/shared-types';

export function getVideoConferencingProvider(
  videoConferencing: ISessionVideoConferencing,
): string | null {
  switch (videoConferencing) {
    case 'guider':
      return 'Guider Video';
    case 'ms-teams':
      return 'Microsoft Teams';
    default:
      return null;
  }
}
