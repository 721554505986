import { defaultPalette } from '@guider-global/ui';
import {
  PaletteColorOptions,
  createTheme,
  responsiveFontSizes,
} from '@mui/material';

let theme = createTheme({
  palette: {
    ...defaultPalette,
    primary: {
      ...(defaultPalette.primary as PaletteColorOptions),
      light: '#7C79F6',
      main: '#5C58F4',
      dark: '#403DAA',
    },
    secondary: {
      ...(defaultPalette.secondary as PaletteColorOptions),
      light: '#9557C1',
      main: '#7B2EB2',
      dark: '#56207C',
    },
    error: {
      ...(defaultPalette.error as PaletteColorOptions),
      light: '#EE3333',
      main: '#EA0000',
      dark: '#A30000',
    },
    info: {
      ...(defaultPalette.info as PaletteColorOptions),
      light: '#5F8DD9',
      main: '#3771D0',
      dark: '#264F91',
    },
    success: {
      ...(defaultPalette.success as PaletteColorOptions),
      light: '#46996D',
      main: '#188049',
      dark: '#105933',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.60)',
    },
  },
  typography: (palette) => ({
    color: palette.text.primary,
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h3: {
      color: palette.text.primary,
      fontSize: '3rem',
      fontWeight: 600,
    },
    h4: {
      color: palette.text.primary,
      fontSize: '2rem',
      fontWeight: 600,
    },
    h5: {
      color: palette.text.primary,
      fontWeight: 600,
    },
    h6: {
      color: palette.text.primary,
      fontWeight: 600,
    },
    body1: {
      color: palette.text.primary,
      fontWeight: 500,
    },
  }),
});

theme = responsiveFontSizes(theme);

export { theme };

export default theme;
