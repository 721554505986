import { IOrganizationBySlugResponse } from '@guider-global/sanity-api';
import {
  IProfile,
  SanityPersonalDetails,
  SanityProfileField,
  ICustomField,
  SanityOrganization,
  SanityAdditionalAgreement,
  SanityPersonalDetail,
} from '@guider-global/shared-types';
import { getAgreementNames } from 'utils/getAgreementNames';
import { snakeToCamelCase } from 'utils/snakeToCamelCase';

type IProfileKeys = Array<keyof IProfile>;
type SanityPersonalDetailsKeys = Array<keyof SanityPersonalDetails>;

interface IGetRequiredFieldProps {
  sanityOrganizationPersonalDetails: SanityPersonalDetails;
  organizationAgreements: SanityAdditionalAgreement[] | undefined;
  sanityOrganizationProfileFields: SanityProfileField[];
}

interface IGetIncompleteProfileFieldsProps {
  sanityOrganization:
    | SanityOrganization
    | IOrganizationBySlugResponse
    | undefined;
  organizationAgreements: SanityAdditionalAgreement[] | undefined;
  profile: IProfile;
}

export interface MissingProfileField {
  type: string;
  slug: string;
  fieldData?: SanityProfileField;
  agreementData?: SanityAdditionalAgreement;
}
export interface MissingAgreementField {
  type: string;
  slug: string;
  agreementData?: SanityAdditionalAgreement;
}

const getRequiredFields = ({
  sanityOrganizationPersonalDetails,
  organizationAgreements: sanityOrganizationAgreements,
  sanityOrganizationProfileFields,
}: IGetRequiredFieldProps) => {
  const personalFieldKeys = Object.keys(
    sanityOrganizationPersonalDetails,
  ) as SanityPersonalDetailsKeys;

  const requiredPersonalFields: string[] =
    personalFieldKeys.reduce((prev: string[], key) => {
      const formatKey =
        key === 'linkedin_url' ? 'linkedInUrl' : snakeToCamelCase(key);
      const field = sanityOrganizationPersonalDetails[
        key
      ] as SanityPersonalDetail;
      if (field.enabled && field.required) return [...prev, formatKey];
      return prev;
    }, []) ?? [];

  const requiredProfileFields: MissingProfileField[] =
    sanityOrganizationProfileFields.reduce(
      (prev: { type: string; slug: string }[], customProfileField) => {
        const required = customProfileField.required;
        if (required)
          return [
            ...prev,
            {
              type: customProfileField.type,
              slug: customProfileField.id.current,
              fieldData: customProfileField,
            },
          ];
        return prev;
      },
      [],
    ) ?? [];

  const organizationAgreements: MissingAgreementField[] =
    sanityOrganizationAgreements?.map((agreement) => {
      const {
        agreement_name: { current },
        _key,
      } = agreement;

      const { slug } = getAgreementNames({
        name: current,
        id: _key,
      });
      return {
        slug,
        type: 'agreement',
        agreementData: agreement,
      };
    }) ?? [];

  return {
    requiredPersonalFields,
    requiredProfileFields,
    organizationAgreements,
  };
};

const getCompletedProfileFields = (profile: IProfile) => {
  const { organizationFields, ...rest } = profile;
  const profileKeys = Object.keys(rest) as IProfileKeys;
  const profileCustomFields = organizationFields as ICustomField[];

  const completedProfileFields = profileKeys.reduce(
    (prev: IProfileKeys, key) => {
      const field = profile[key];
      if (field) return [...prev, key];
      return prev;
    },
    [],
  );
  const completedCustomProfileFields = profileCustomFields.map(
    (customField) => {
      return customField.fieldSlug;
    },
  );
  const completedFields = [
    ...completedProfileFields,
    ...completedCustomProfileFields,
  ];

  return completedFields;
};

export const getIncompleteProfileFields = ({
  profile,
  sanityOrganization,
  organizationAgreements,
}: IGetIncompleteProfileFieldsProps) => {
  if (!sanityOrganization) return [];
  const sanityOrganizationProfileFields =
    sanityOrganization.profile_fields ?? [];
  const sanityOrganizationPersonalDetails = sanityOrganization.personal_details;
  const requiredFields = getRequiredFields({
    sanityOrganizationPersonalDetails,
    organizationAgreements,
    sanityOrganizationProfileFields,
  });

  const completedFields = getCompletedProfileFields(profile);

  const incompletePersonalFields = requiredFields.requiredPersonalFields.filter(
    (field) => {
      const isCompleted = completedFields.includes(field);
      if (!isCompleted) return field;
      return undefined;
    },
  );

  const incompleteProfileFields = requiredFields.requiredProfileFields.filter(
    (field) => {
      const isCompleted = completedFields.includes(field.slug);
      if (!isCompleted) return field;

      return undefined;
    },
  );

  const incompleteOrganizationAgreements =
    requiredFields.organizationAgreements.filter((field) => {
      const isCompleted = completedFields.includes(field.slug);
      if (!isCompleted) return field;

      return undefined;
    });

  return [
    ...incompletePersonalFields,
    ...incompleteProfileFields,
    ...incompleteOrganizationAgreements,
  ];
};
