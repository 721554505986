import {
  Image,
  Stack,
  TextStack,
  ButtonProps,
  ButtonStack,
} from '@guider-global/ui';
import EmptyState from 'assets/no_relationships_avatar.svg';

export type ProgramMembershipEmptyTableViewProps = {
  heading: string | undefined;
  descriptions: string[];
  leftButton: ButtonProps | undefined;
  rightButton: ButtonProps | undefined;
};

export function ProgramMembershipEmptyTableView({
  heading,
  descriptions,
  leftButton,
  rightButton,
}: ProgramMembershipEmptyTableViewProps) {
  return (
    <Stack
      direction="column"
      align="center"
      spacing={2}
      sx={{
        py: 6,
        border: `1px solid #F2F4F7`,
        borderRadius: '16px',
        px: 2,
      }}
    >
      <Image src={EmptyState} width="180px" />
      <TextStack
        size="medium"
        align="center"
        heading={heading}
        spacing={1}
        subtitles={descriptions}
        sx={{ textAlign: 'center' }}
      />
      {rightButton && leftButton && (
        <ButtonStack
          buttonsWidth="100%"
          buttonsColor="info"
          direction={{ xs: 'column', md: 'row' }}
          width={{ xs: 'auto', md: '450px' }}
          buttons={[
            {
              variant: 'contained',
              ...leftButton,
              key: 'no-relationships-button-left',
            },
            {
              variant: 'outlined',
              ...rightButton,
              key: 'no-relationships-button-right',
            },
          ]}
        />
      )}
    </Stack>
  );
}
