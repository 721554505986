import { ITokenVideo } from '@guider-global/shared-types';
import { RootState } from 'store';
import { restReduxGenerator } from '@guider-global/redux-axios-hooks';

export const initialVideoTokenState: ITokenVideo = {
  token: '',
  expiresOn: undefined,
};

export default restReduxGenerator<'videoToken', ITokenVideo>(
  'videoToken',
  initialVideoTokenState,
);

export const selectVideoToken = (state: RootState) => state.videoToken;
