import { ReactNode } from 'react';
import { BasicModal, IconButton, Stack, Text } from '@guider-global/ui';
import Close from '@mui/icons-material/Close';

export type NotificationModalViewProps = {
  open?: boolean;
  headingText: string | undefined;
  closeButtonLabel: string;
  onClose?: () => void;
  children: ReactNode;
};

export function NotificationModalView({
  open,
  headingText,
  closeButtonLabel,
  onClose,
  children,
}: Readonly<NotificationModalViewProps>) {
  const header = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        flex: 'auto',
        px: { xs: 2, md: 8 },
        '> .MuiButtonBase-root': {
          color: 'black',
        },
      }}
    >
      <Text variant="h2" text={headingText} lineHeight={1.5} />
      <IconButton
        variant="regular"
        onClick={onClose}
        disabled={false}
        icon={<Close />}
      />
    </Stack>
  );

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      paperSx={{
        backgroundColor: '#F9FAFB',
        borderRadius: '16px',
        border: 'none',
        p: 3,
      }}
      header={header}
    >
      <Stack
        component="div"
        direction="column"
        spacing={1}
        sx={{
          py: 2,
          px: { xs: 2, md: 8 },
          mb: 2,
        }}
      >
        {children}
      </Stack>
    </BasicModal>
  );
}
