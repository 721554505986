import { IRestState, PickByType } from '@guider-global/redux-axios-hooks';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '.';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// Rest only states selector
export const useRestAppSelector: TypedUseSelectorHook<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PickByType<RootState, IRestState<any>>
> = useSelector;
