import { ReactNode } from 'react';
import { FieldErrorsImpl, FieldValues, Path } from 'react-hook-form';

type ReactHookFormErrorTypes =
  | 'maxLength'
  | 'minLength'
  | 'validate'
  | 'required';

export function handleCustomFormErrors<TFieldValues extends FieldValues>(
  error: FieldErrorsImpl<TFieldValues>[Path<TFieldValues> &
    (string | undefined)],
  maxLengthErrorMessage = 'Max length exceeded',
  minLengthErrorMessage = 'Min length exceeded',
  validateErrorMessage = 'Validation failed',
  requiredErrorMessage = 'Required field',
): ReactNode {
  if (!error) {
    return <></>;
  } else {
    const errorType = error.type as ReactHookFormErrorTypes;
    const message = error.message ?? validateErrorMessage;

    switch (errorType) {
      case 'maxLength':
        return <>{maxLengthErrorMessage}</>;
      case 'minLength':
        return <>{minLengthErrorMessage}</>;
      case 'validate':
        return <>{message}</>;
      case 'required':
        return <>{requiredErrorMessage}</>;
      default:
        return <>{'Invalid'}</>;
    }
  }
}
