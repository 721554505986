import { IVideo } from '@guider-global/shared-types';
import { RootState } from 'store';
import { restReduxGenerator } from '@guider-global/redux-axios-hooks';

export const initialVideoState: IVideo = {
  relationshipId: '',
  userId: '',
  profileId: '',
  communicationUserId: '',
  displayName: '',
  sessionId: '',
  sessionName: '',
  roomId: '',
  endpointUrl: '',
  threadId: '',
};

export default restReduxGenerator<'video', IVideo>('video', initialVideoState);

export const selectVideo = (state: RootState) => state.video;
