import {
  AvatarBox,
  CollapsibleCard,
  Divider,
  ProgramMembershipDetailsView,
  ProgramMembershipDetailsViewProps,
  Stack,
} from '@guider-global/ui';
import {
  IDashboardRelationshipsHeaders,
  IDashboardRelationshipsRow,
  ProgramMembershipTableView,
} from '../ProgramMembershipTableView';
import {
  ProgramMembershipEmptyTableView,
  ProgramMembershipEmptyTableViewProps,
} from '../ProgramMembershipEmptyTableView';
import { Box } from '@mui/system';

export interface ProgramMembershipsGuideContent {
  guideMembershipDetails?: ProgramMembershipDetailsViewProps;
  guideRelationshipRows: IDashboardRelationshipsRow[];
  guideRelationshipsEmptyState?: ProgramMembershipEmptyTableViewProps;
}
export interface ProgramMembershipsTraineeContent {
  traineeMembershipDetails?: ProgramMembershipDetailsViewProps;
  traineeRelationshipRows: IDashboardRelationshipsRow[];
  traineeRelationshipsEmptyState?: ProgramMembershipEmptyTableViewProps;
}

export type ProgramMembershipsCardProps = {
  title: string;
  description?: string;
  avatarSrc?: string;
  guideMembership: ProgramMembershipsGuideContent;
  traineeMembership: ProgramMembershipsTraineeContent;
  relationshipsTableHeaders: IDashboardRelationshipsHeaders;
  group?: boolean;
};

export function ProgramMembershipsCard({
  title,
  description,
  avatarSrc,
  guideMembership,
  traineeMembership,
  relationshipsTableHeaders,
  group = false,
}: ProgramMembershipsCardProps) {
  const {
    traineeMembershipDetails,
    traineeRelationshipRows,
    traineeRelationshipsEmptyState,
  } = traineeMembership;

  const {
    guideMembershipDetails,
    guideRelationshipRows,
    guideRelationshipsEmptyState,
  } = guideMembership;

  return (
    <CollapsibleCard
      headerElement={
        <AvatarBox
          size="medium"
          avatarSrc={avatarSrc}
          heading={title}
          subtitles={[description]}
        />
      }
      sx={{
        borderRadius: '16px',
        backgroundColor: '#FEFEFE',
        boxShadow: '0px 4px 30px 0px #4D54640D',
        border: 'none',
      }}
    >
      {traineeMembershipDetails && (
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          p={4}
          gap={3}
          sx={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Stack
            direction={'row'}
            spacing={0}
            gap={0}
            width={{ xs: '100%', md: '35%' }}
          >
            <Box
              position={'absolute'}
              height={'58px'}
              width={'14px'}
              bgcolor={'#00DEBF'}
              borderRadius={'10px'}
              left={'-7px'}
            />
            <ProgramMembershipDetailsView
              title={traineeMembershipDetails.title}
              description={traineeMembershipDetails.description}
              fields={traineeMembershipDetails.fields}
              button={traineeMembershipDetails.button}
            />
          </Stack>

          <Stack direction={'column'} width={{ xs: '100%', md: '65%' }}>
            {traineeRelationshipRows.length > 0 && (
              <ProgramMembershipTableView
                rows={traineeRelationshipRows}
                headers={relationshipsTableHeaders}
              />
            )}
            {traineeRelationshipsEmptyState && (
              <ProgramMembershipEmptyTableView
                heading={traineeRelationshipsEmptyState?.heading}
                descriptions={traineeRelationshipsEmptyState?.descriptions}
                leftButton={traineeRelationshipsEmptyState.leftButton}
                rightButton={traineeRelationshipsEmptyState.rightButton}
              />
            )}
          </Stack>
        </Stack>
      )}
      {traineeMembershipDetails && guideMembershipDetails && <Divider />}
      {guideMembershipDetails && (
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          p={4}
          gap={3}
          sx={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Stack
            direction={'row'}
            spacing={0}
            gap={0}
            width={{ xs: '100%', md: '35%' }}
          >
            <Box
              position={'absolute'}
              height={'58px'}
              width={'14px'}
              bgcolor={'#008CF9'}
              borderRadius={'10px'}
              left={'-7px'}
            />
            <ProgramMembershipDetailsView
              title={guideMembershipDetails.title}
              description={guideMembershipDetails.description}
              fields={guideMembershipDetails.fields}
              chip={guideMembershipDetails.chip}
              button={guideMembershipDetails.button}
            />
          </Stack>
          <Stack direction={'column'} width={{ xs: '100%', md: '65%' }}>
            {guideRelationshipRows.length > 0 && (
              <ProgramMembershipTableView
                isGroupGuide={group}
                rows={guideRelationshipRows}
                headers={relationshipsTableHeaders}
              />
            )}
            {guideRelationshipsEmptyState && (
              <ProgramMembershipEmptyTableView
                heading={guideRelationshipsEmptyState.heading}
                descriptions={guideRelationshipsEmptyState.descriptions}
                leftButton={guideRelationshipsEmptyState.leftButton}
                rightButton={guideRelationshipsEmptyState.rightButton}
              />
            )}
          </Stack>
        </Stack>
      )}
    </CollapsibleCard>
  );
}
