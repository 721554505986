import { Card, Text, Divider, Stack, TextStack } from '@guider-global/ui';
import { useTheme } from '@mui/material';
import { interpolate } from 'functions';

export type SurveyCardViewProps = {
  firstName: string | undefined;
  lastName: string | undefined;
  title: string | undefined;
  roleSingular: string | undefined;
  programName: string | undefined;
  programTypeNoun: string | undefined;
  children: JSX.Element;
};

export function SurveyCardView({
  firstName,
  lastName,
  children,
  title,
  programName,
  roleSingular,
  programTypeNoun,
}: SurveyCardViewProps) {
  const { palette } = useTheme();

  return (
    <Card
      topBorderColor={palette.secondary.main}
      sx={{ width: '650px', pb: 3 }}
    >
      <Text
        sx={{ p: 2 }}
        variant="h3"
        text={interpolate(title ?? '', {
          guideSingular: roleSingular ?? '',
          guideFirstName: firstName ?? '',
          programTypeNoun: programTypeNoun ?? '',
        })}
      />
      <Divider width="100%" />
      <Stack
        direction={'column'}
        sx={{
          pt: 3,
          paddingBottom: 0,
          px: { xs: 2, md: 4.5 },
        }}
      >
        <TextStack
          spacing={1}
          heading={{ text: `${firstName} ${lastName}`, variant: 'h4' }}
          subtitles={[
            {
              text: `${roleSingular} - ${programName}`,
              variant: 'body1Secondary',
            },
          ]}
          size="medium"
        />
        <Divider width="100%" sx={{ my: 3 }} />
        {children}
      </Stack>
    </Card>
  );
}
