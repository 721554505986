import { Stack, StaticAlert, IconCheckbox, Text } from '@guider-global/ui';
import People from '@mui/icons-material/People';

export type Role = 'both' | 'trainee' | 'guide' | undefined;

export interface ParticipationViewProps {
  title: string | undefined;
  alertLabel?: string;
  bothText?: { title?: string; description?: string };
  guideText?: { title?: string; description?: string };
  traineeText?: { title?: string; description?: string };
  handleRoleSelection: (role: Role) => void;
  currentRole: Role;
}

export function ParticipationView({
  title,
  alertLabel,
  guideText,
  traineeText,
  bothText,
  handleRoleSelection,
  currentRole,
}: ParticipationViewProps) {
  return (
    <Stack
      direction={'column'}
      justifyContent={'flex-start'}
      width={{ xs: '100%', md: '480px' }}
    >
      <Text text={title} variant="h2" lineHeight={'115%'} />
      {alertLabel && (
        <StaticAlert
          message={alertLabel}
          fullWidth
          sx={{ backgroundColor: 'warning.transparent', color: 'warning.main' }}
        />
      )}
      <IconCheckbox
        icon={<People />}
        isChecked={currentRole === 'both'}
        onChange={() => {
          handleRoleSelection('both');
        }}
        heading={bothText?.title ?? 'Both'}
        subtitle={bothText?.description ?? 'Both'}
        data-cy="register-participation-both-checkbox"
      />
      <IconCheckbox
        icon={<People />}
        isChecked={currentRole === 'guide'}
        onChange={() => {
          handleRoleSelection('guide');
        }}
        heading={guideText?.title}
        subtitle={guideText?.description}
        data-cy="register-participation-guide-checkbox"
      />
      <IconCheckbox
        icon={<People />}
        isChecked={currentRole === 'trainee'}
        onChange={() => {
          handleRoleSelection('trainee');
        }}
        heading={traineeText?.title}
        subtitle={traineeText?.description}
        data-cy="register-participation-trainee-checkbox"
      />
    </Stack>
  );
}
