import {
  CheckListItemProps,
  ProgressIndicatorDial,
  Stack,
  Text,
  TextProps,
  CheckList,
} from '@guider-global/ui';
import { useMediaQuery, useTheme } from '@mui/material';

export type OnboardingChecklistViewProps = {
  heading: string | undefined;
  percentCompleteLabel: string | undefined;
  progressDescription?: string;
  progressDialSize?: number;
  description?: string | TextProps;
  steps?: ({ percentCompletion: number } & CheckListItemProps)[];
};

export function OnboardingChecklistView({
  heading,
  progressDescription,
  description,
  steps = [],
}: OnboardingChecklistViewProps) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const percentageCompleted = steps
    .map((step) => {
      if (step.isChecked) {
        return step.percentCompletion;
      } else {
        return 0;
      }
    })
    .reduce((sum, currentValue) => sum + currentValue);

  const descriptionElement =
    typeof description === 'string' ? (
      <Text
        text={description}
        variant="caption"
        fontSize={{ xs: 12, md: 16 }}
      />
    ) : (
      <Text {...description} />
    );

  return (
    <Stack direction={'column'}>
      <ProgressIndicatorDial
        metricLabel={`${percentageCompleted}%`}
        percentComplete={percentageCompleted}
        metricDescription={isMd ? progressDescription : undefined}
        size={12}
        containerSx={{ height: '100%', border: 'none' }}
      />
      <Text
        variant="h6"
        text={descriptionElement}
        sx={{ display: { xs: 'none', md: 'inherit' } }}
      />
      <CheckList items={steps} />
    </Stack>
  );
}
