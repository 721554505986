import {
  ButtonStack,
  ButtonStackItem,
  Stack,
  TextStack,
  TextStackProps,
} from '@guider-global/ui';
import { Box } from '@mui/material';

export type FilterPageViewProps = {
  title: string | undefined;
  subtitles: TextStackProps['subtitles'];
  children?: JSX.Element | JSX.Element[];
  buttons?: ButtonStackItem[];
  displayButtons?: boolean;
};

export function FilterPageView({
  title,
  subtitles,
  children,
  buttons,
  displayButtons = true,
}: FilterPageViewProps) {
  return (
    <Stack
      direction="column"
      width="100%"
      spacing={0}
      sx={{ backgroundColor: '#F9FAFB' }}
      justifyContent={'center'}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 6,
          gap: 3,
        }}
      >
        <TextStack
          heading={{ text: title, variant: 'h1', component: 'h1' }}
          subtitles={subtitles}
          size="xl"
          sx={{ width: { xs: '90%', md: '70%' } }}
        />

        <Box
          sx={{
            width: { xs: '90%', md: '70%' },
            display: displayButtons ? '' : 'none',
          }}
        >
          {buttons && (
            <ButtonStack
              direction={'row'}
              flexWrap={'wrap'}
              rowGap={2}
              buttons={buttons}
              gap={0}
              spacing={0}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}
