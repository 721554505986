import { Stack } from '@mui/material';
import {
  ButtonStack,
  Chip,
  CircularAvatarPair,
  TextStack,
} from '@guider-global/ui';

export interface ScheduleSessionViewProps {
  otherUserAvatar: string;
  userAvatar: string;
  programLabel: string;
  heading: string | undefined;
  description: string | undefined;
  primaryButtonLabel: string | undefined;
  primaryButtonOnClick: () => void;
  secondaryButtonLabel?: string;
  secondaryButtonOnClick?: () => void;
}

export function ScheduleSessionView({
  otherUserAvatar,
  userAvatar,
  programLabel,
  heading,
  description,
  primaryButtonLabel,
  primaryButtonOnClick,
  secondaryButtonLabel,
  secondaryButtonOnClick,
}: ScheduleSessionViewProps) {
  return (
    <Stack
      height="100%"
      direction={'column'}
      alignItems={'center'}
      gap={{ xs: 4, md: 2 }}
      justifyContent={'space-around'}
    >
      <CircularAvatarPair
        mainAvatar={otherUserAvatar}
        secondaryAvatar={userAvatar}
      />
      <Stack direction={'column'} gap={2} alignItems={'center'}>
        {programLabel && (
          <Chip
            label={programLabel}
            color="info"
            sx={{ width: 'fit-content' }}
          />
        )}
        <TextStack
          size="large"
          heading={heading}
          spacing={1}
          align="center"
          subtitles={[
            {
              text: description,
              fontSize: '16px',
              color: 'text.secondary',
              textAlign: 'center',
            },
          ]}
          sx={{
            h2: {
              lineHeight: '115%',
            },
          }}
        />
      </Stack>

      <ButtonStack
        width={{
          xs: '100%',
          md: secondaryButtonLabel && secondaryButtonOnClick ? '70%' : '40%',
        }}
        direction={{ xs: 'column', md: 'row' }}
        spacing={1.5}
        buttons={[
          {
            key: 'btn1',
            variant: 'contained',
            label: primaryButtonLabel,
            onClick: primaryButtonOnClick,
            color: 'info',
            sx: { px: 3 },
          },
          {
            key: 'btn2',
            variant: 'outlined',
            label: secondaryButtonLabel,
            onClick: secondaryButtonOnClick,
            color: 'info',
            sx: {
              px: 3,
              display:
                secondaryButtonLabel && secondaryButtonOnClick ? '' : 'none',
            },
          },
        ]}
      />
    </Stack>
  );
}
