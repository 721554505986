import { KeyedProps } from '@guider-global/shared-types';
import {
  Button,
  KeyStat,
  KeyStatProps,
  Leaderboard,
  LeaderboardRow,
  ProgressIndicatorDial,
  Stack,
} from '@guider-global/ui';
import BarChartIcon from '@mui/icons-material/BarChart';

export type ProgressLeaderboardCardViewProps = {
  keyStats: KeyedProps<KeyStatProps>[];
  dialSize?: number;
  dialMetricLabel: string;
  dialMetricDescription: string | undefined;
  leaderboardRows: LeaderboardRow[];
  selectedLeaderboardRow?: number;
  viewLeaderboardLabel: string | undefined;
  onViewLeaderboard?: () => void;
  showLeaderboard?: boolean;
};

export function ProgressLeaderboardCardView({
  keyStats,
  dialSize = 12,
  dialMetricLabel,
  dialMetricDescription,
  leaderboardRows,
  selectedLeaderboardRow,
  viewLeaderboardLabel,
  onViewLeaderboard,
  showLeaderboard,
}: ProgressLeaderboardCardViewProps) {
  return (
    <Stack direction="column" gap={2} height={'420px'}>
      <Stack direction="row" justifyContent={'space-evenly'}>
        {keyStats.map((stat) => (
          <KeyStat
            key={stat.key}
            value={stat.value}
            label={stat.label}
            align="center"
          />
        ))}
      </Stack>

      {showLeaderboard ? (
        <Stack direction={'column'} justifySelf={'flex-end'} flex={'1 1 0'}>
          <Leaderboard
            rows={leaderboardRows}
            rowsPerPage={3}
            showNavigator={false}
            selected={selectedLeaderboardRow}
          />
          <Button
            startIcon={<BarChartIcon />}
            label={viewLeaderboardLabel}
            variant="text"
            color="info"
            onClick={onViewLeaderboard}
            sx={{ height: 'fit-content' }}
          />
        </Stack>
      ) : (
        <ProgressIndicatorDial
          percentComplete={100}
          size={dialSize}
          metricLabel={dialMetricLabel}
          metricDescription={dialMetricDescription}
          containerSx={{ mt: 2 }}
        />
      )}
    </Stack>
  );
}
