import { buildSanityImageUrl } from '@guider-global/sanity-hooks';
import { SanityImage } from '@guider-global/shared-types';

export const getImageSource = ({
  source,
  width,
}: {
  source?: SanityImage;
  width?: number;
}) => {
  if (!source) return undefined;
  return buildSanityImageUrl({ source, width });
};
