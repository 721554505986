export interface GetAgreementNamesProps {
  name: string;
  id: string;
}
export interface GetAgreementNamesResults {
  slug: string;
  customCheckboxName: string;
}

export const getAgreementNames = ({
  name,
  id,
}: GetAgreementNamesProps): GetAgreementNamesResults => {
  const regex = /[^A-Za-z0-9]/g;
  const validatedAgreementName = name.replaceAll(regex, '');

  const slug = `${validatedAgreementName}-${id}`;
  const customCheckboxName = slug;

  return {
    customCheckboxName,
    slug,
  };
};
